import "./index.less";
const AboutUs = () => {
  
  return (
    <div className="h5-about-root" id="aboutRoot">
      <div className="h5-page-content">
        <div className="h5-about-page-content">
          <div key="about-ani" className="h5-about-content">
            <div className="h5-about-title">About Us</div>
            <div className="h5-about-des">
              <p>
              Founded in 2014, Bixin Group is a leading blockchain technology group specializing in blockchain security, and aims to promote and contribute to the crypto industry and Web 3.0-related communities. Bixin Group's ecosystem consists of Miners R&D and manufacturing, mining operations, custodial wallet, MPC wallet, hardware wallet, trading platform, public chain, and venture capital.
              </p>
              <p>
              Bixin Group upholds a longstanding collaboration with the Bitcoin core community, providing users with a simple yet secure one-stop blockchain solution. The group is dedicated to shaping the world through blockchain technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
