import {openNewTabURL} from '../../utils';
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as RightIcon } from "../../assets/page_right.svg";
import onekey_classic from "../../assets/onekey_classic.png";
import onekey_mini from "../../assets/onekey_mini.png";
import onekey_touch from "../../assets/onekey_touch.png";
import onekey_lite from "../../assets/onekey_lite.png";
import "./index.less";
const OneKey = () => {
  return (
    <div className="h5-one-key-root" id="oneKeyRoot">
      <div className="h5-page-content h5-one-key-page-content">
        <div className="h5-one-key-title">ONEKEY</div>
        <div className="h5-one-key-statistic">
          Encrypted hardware wallet
        </div>
        <div className="h5-one-key-des">
          Simple yet secure. Truly open source, with no hidden codes. Open to
          public inspection.
        </div>
        <Swiper
          id="onekeyswiper"
          key="onekeyswiper"
          className="onekeyswiper"
          // direction="vertical"
          modules={[Navigation]}
          navigation
          slidesPerView={1}
        >
          <SwiperSlide>
            <div className="h5-onkey-slide">
              <div className="h5-onkey-slide-name">OneKey Classic</div>
              <img src={onekey_classic} alt="onekey_classic" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h5-onkey-slide">
              <div className="h5-onkey-slide-name">OneKey Mini</div>
              <img src={onekey_mini} alt="onekey_mini" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h5-onkey-slide">
              <div className="h5-onkey-slide-name">OneKey Touch</div>
              <img src={onekey_touch} alt="onekey_touch" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h5-onkey-slide">
              <div className="h5-onkey-slide-name">OneKey Lite</div>
              <img
                src={onekey_lite}
                alt="onekey_lite"
                style={{ width: "80%", marginTop: "10px" }}
              />
            </div>
          </SwiperSlide>
        </Swiper>
      

        <div className="h5-one-key-splite-des">
          No storage of users’ private keys or recovery phrases. All hardware
          and software are open sources. End-to-end encryption and
          industry-leading encryption technology allow users to keep information
          locally.
        </div>
        <div className="h5-one-key-btn"  onClick={()=>{
              openNewTabURL('https://onekey.so/')
            }}>
          ENTER <RightIcon />
        </div>
      </div>
    </div>
  );
};

export default OneKey;
