import React, { useEffect, useState } from "react";
import { Navigation, Mousewheel,EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import classnames from "classnames";
import Footer from "./Footer";
import HomePage from "./Home";
import AboutUs from "./AboutUs";
import WhyUs from "./WhyUs";
import Ecosystem from "./Ecosystem";
import Point from "./Point";
import BixinWallet from "./BixinWallet";
import OpenBlock from "./OpenBlock";
import OneKey from "./Onekey";
// import Starcoin from './Starcoin'
import BixinVentures from './BixinVentures'
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/mousewheel";
import "swiper/css/effect-fade";
import "./index.less";

const linkName = [
  "Home",
  "About Us",
  "Why Us",
  "Ecosystem",
  "Bixin.com",
  "OpenBlock",
  "OneKey",
  // "StarCoin",
  "Bixin Ventures"
];

const Home = () => {
  const [step, setStep] = useState(0);
  const [start, setStart] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setStart(true);
    }, 10);
  }, []);
  console.log("stepstep", step);
  return (
    <div className="templates-wrapper">
      <div className="global-bg">
        {step === 0 || step === 1 ? (
          <div
            className={classnames("global-bg-cc", {
              mincc: step === 1,
              ccProcessStart: start,
            })}
          />
        ) : null}
        <div
          className={classnames("global-bg-bixin", {
            minbixincc: step !== 0,
            bixinProcessStart: start,
          })}
        />
      </div>
     
      <Swiper
        id="homeswiper"
        key="homeswiper"
        direction="vertical"
        followFinger={false}
        modules={[Navigation, Mousewheel,EffectFade]}
        effect="fade"
        navigation={{
          prevEl: ".home-swiper-button-prev",
          disabledClass: "home-swiper-button-disabled",
        }}
        // navigation
        slidesPerView={1}
        className="homeswiper swiper-no-swiping"
        mousewheel={true}
        onSlideChange={({ activeIndex }) => {
          console.log("slide change", activeIndex);
          setStep(activeIndex);
        }}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide> {step === 0 ? <HomePage /> : null}</SwiperSlide>
        <SwiperSlide>{step === 1 ? <AboutUs step={step} /> : null}</SwiperSlide>
        <SwiperSlide>{step === 2 ? <WhyUs step={step} /> : null}</SwiperSlide>
        <SwiperSlide>
          {step === 3 ? <Ecosystem step={step} /> : null}
        </SwiperSlide>
        <SwiperSlide>
          {step === 4 ? <BixinWallet step={step} /> : null}
        </SwiperSlide>
        <SwiperSlide>
          {step === 5 ? <OpenBlock step={step} /> : null}
        </SwiperSlide>
        <SwiperSlide>{step === 6 ? <OneKey step={step} /> : null}</SwiperSlide>
        {/* <SwiperSlide>{step === 7 ? <Starcoin step={step} /> : null}</SwiperSlide> */}
        <SwiperSlide> <BixinVentures step={step} /></SwiperSlide>
        <Point linkName={linkName} step={step} setStep={setStep} />
        <div className="home-swiper-button-prev"/>
        <Footer />
      </Swiper>
    </div>
  );
};

export default Home;
