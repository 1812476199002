import TweenOne from "rc-tween-one";
import "./index.less";

const AboutUs = () => {
  return (
    <div className="about-root" id="aboutRoot">
      <div className="page-content">
        <div className="about-page-content">
          <TweenOne
            animation={{
              x: 0,
              opacity: 1,
              duration: 500
            }}
            style={{ transform: "translate(-100px, 0px)", opacity: 0 }}
          >
            <div key="about-ani" className="about-content">
              <div className="about-title">About Us</div>
              <div className="about-des">
                <div>
                Founded in 2014, Bixin Group is a leading blockchain technology group specializing in blockchain security, and aims to promote and contribute to the crypto industry and Web 3.0-related communities. Bixin Group's ecosystem consists of Miners R&D and manufacturing, mining operations, custodial wallet, MPC wallet, hardware wallet, trading platform, public chain, and venture capital.
                </div>
                <div>
                Bixin Group upholds a longstanding collaboration with the Bitcoin core community, providing users with a simple yet secure one-stop blockchain solution. The group is dedicated to shaping the world through blockchain technology.
                </div>
              </div>
            </div>
          </TweenOne>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
