import { Statistic } from "antd";
import CountUp from "react-countup";
import {openNewTabURL} from '../../utils';
import { ReactComponent as RightIcon } from "../../assets/page_right.svg";
import bixin_wallet from "../../assets/bixin_wallet.png";
import "./index.less";

const formatter = (value) => <CountUp end={value} separator="," />;
const formatters = (value) => (
  <CountUp end={value} separator="," decimals={2} />
);

const BixinWallet = () => {
  return (
    <div className="h5-bixin-wallet-root"  id="bixinWalletRoot">
      <div className="h5-page-content h5-bixin-wallet-page-content">
        <div className="h5-bixin-wallet-title">BIXIN.COM</div>
        <img
          src={bixin_wallet}
          className="h5-bixinwallet_img"
          alt="h5-bixin_wallet"
        />
        {/* <div className="h5-bixin-wallet-statistic">
          <div>
            <Statistic value={3000000} formatter={formatter} />
          </div>
          <div>Annual Internal transactions</div>{" "}
          <div style={{marginTop:'19px'}}>
            <Statistic prefix="$" value={1850000} formatter={formatters} />
          </div>
          <div>Annual Cost Savings for Users</div>{" "}
        </div> */}
        <div className="h5-bixin-wallet-des">
        <p>
          BIXIN.COM, formerly known as HaoBTC, established in 2014, is a virtual asset service platform authorized and regulated by regulatory authorities, providing users with "Simple, yet Secure" virtual asset services. The platform has been safely operated for nine years with zero security breaches, providing institutional-level protection for users' VAs through hot and cold wallet segregation, 
          multiple signatures, and military-grade hardware encryption mechanisms. 
        </p>
        {/* <p>
          BIXIN.COM is registered with AUSTRAC (an Australian government agency) as a Digital Currency Exchange Provider and Remittance Services Provider. All business operations of bixin.com are carried out under strict supervision and comply with relevant laws and regulations.
        </p> */}
        </div>

        <div className="h5-bixin-wallet-btn" onClick={()=>{
              openNewTabURL('https://www.bixin.com/')
            }}>
          ENTER <RightIcon />
        </div>
      </div>
    </div>
  );
};

export default BixinWallet;
