import QueueAnim from "rc-queue-anim";

import "./index.less";

const HomePage = () => {
  return (
    <div className="home-root" id="homeRoot">
      <QueueAnim type={["top", "bottom"]} delay={500}>
        <div className="home-wrapper" key="home-ani">
          <div className="home-des">
            <div>A Decade of Excellence</div>
            <div>Enable the Next Disruptive Web 3.0 Innovation</div>
          </div>
        </div>
      </QueueAnim>
    </div>
  );
};
export default HomePage