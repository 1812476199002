import React from "react";
import QueueAnim from "rc-queue-anim";
import { Row, Col } from "antd";
import whysecurity from "../../assets/why_security.png";
import whycapital from "../../assets/why-capital.png";
import whyminingy from "../../assets/why-mining.png";

import "./index.less";
const WhyUs = ({step}) => {
  const childWrapper = [
    {
      icon: whysecurity,
      name: "SECURITY",
      des: "Onekey, one of the best selling hardware wallets; BIXIN.COM, nine years zero breach, trusted by millions.",
    },
    {
      icon: whycapital,
      name: "CAPITAL",
      des: "Bixin Ventures is a global firm focusing on early-stage investments in blockchain companies to accelerate the next disruptive Web 3.0 innovation.",
    },
    {
      icon: whyminingy,
      name: "MINING",
      des: "Bixin Group has been a trailblazer in Bitcoin mining and contributed up to 5% to 10% of the computing power at its peak over the past eight years in the global Bitcoin network.",
    },
  ];

  const Comp = React.forwardRef((props, ref) => {
    return (
      <Row ref={ref} gutter={20} style={{ width: "100%" }}>
        {props.children}
      </Row>
    );
  });
  // if(step !==2) return null;
  return (
    <div className="whyus-root" id="whyusRoot">
      <div className="page-content why-page-content">
        <QueueAnim key="whyss" type={["top", "bottom"]} delay={100} duration={1000}>
          <div key="why-title" className="why-title">
            Why Us
          </div>
        </QueueAnim>
        <QueueAnim
          // type={["bottom", "top"]}
          key="whyusblock"
          // leaveReverse
          component={Comp}
          delay={100}
          duration={2000}
          // ease
          interval={300}
          animConfig={[
            { opacity: [1, 0], translateY: [0, 100],duration:5000 },
          ]}
        >
          {step ===2 ? childWrapper.map((item) => (
            <Col key={item.name} span={8}>
              <div className="wyhus-childWrapper">
                <div className="wyh-item-img-wrapper">
                  <img className="wyh-item-img" src={item.icon} alt={item.name} />
                </div>
                <div className="wyh-item-title">{item.name}</div>
                <div className="wyh-item-des">{item.des}</div>
              </div>
            </Col>
          )) : null}
        </QueueAnim>
        </div>
      {/* </OverPack> */}
    </div>
  );
};

export default WhyUs;