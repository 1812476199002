import React, { useState } from "react";
import { Drawer } from "antd";
import { Link } from "rc-scroll-anim";
import { ReactComponent as BixinLogo } from "../../assets/bixin_logo.svg";
import { ReactComponent as OpenDrawer } from "../../assets/open_drawer.svg";
import "./index.less";

const linkName = {
  homeRoot: "Home",
  aboutRoot: "About Us",
  whyusRoot: "Why Us",
  ecosystemRoot: "Ecosystem",
  bixinWalletRoot: "Bixin.com",
  openBlockRoot: "OpenBlock",
  oneKeyRoot: "OneKey",
  // starcoinRoot: "StarCoin",
  bixinVenturesRoot: "Bixin Ventures",
};

const H5Header = () => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const data = Object.keys(linkName)
  const children = data
    .map((item) => {
      const className = `h5-point`;
      return (
        <Link key={item} className={className} to={item} toHash={false} onClick={
          ()=>{setOpen(false);}
        }>
          {linkName[item]}
        </Link>
      );
    })
    .filter((item) => item);

  return (
    <div className="h5-header-root">
      <div className="h5-header-root-content">
        <BixinLogo className="h5-header-logo" />
        <div
          onClick={() => {
            setOpen(true);
          }}
        >
          <OpenDrawer />
        </div>
      </div>
      <Drawer placement="right" onClose={onClose} open={open} width={'60%'}>
        <div className="h5-point-wrapper">{children}</div>
      </Drawer>
    </div>
  );
};
export default H5Header;
