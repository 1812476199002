import { openNewTabURL } from "../../utils";
import { ReactComponent as RightIcon } from "../../assets/page_right.svg";
import openblockImg from "../../assets/openblock.png";

import "./index.less";
const OpenBlock = () => {
  return (
    <div className="h5-open-block-root" id="openBlockRoot">
      <div className="h5-page-content h5-open-block-page-content">
        <div className="h5-open-block-title">OPENBLOCK</div>
        <img src={openblockImg} className="h5-open-block-img" alt="openblock" />
        <div className="h5-open-block-statistic">Web 3.0 on your fingertip</div>
        <div className="h5-open-block-des">
          Compatible with the MetaMask plugin and the WalletConnect protocol
        </div>
        <div className="h5-open-block-des">
          One-stop DApp aggregation supporting multi-chain ecosystems
        </div>
        <div className="h5-open-block-des">
          MPC wallet without mnemonic facilitates seamlessly connecting to Web 3.0
        </div>
        <div className="h5-open-block-des">
          Multi-party secure computing technology solves single-point failure
        </div>

        <div
          className="h5-open-block-btn"
          onClick={() => {
            openNewTabURL("https://openblock.com/");
          }}
        >
          ENTER <RightIcon />
        </div>
      </div>
    </div>
  );
};

export default OpenBlock;
