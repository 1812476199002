import React,{useState,useEffect} from "react";
import ReactDOM from "react-dom/client";
import { enquireScreen } from "enquire-js";
import "./index.css";
import App from "./Home";
import MobileApp from "./MobileHome";
import reportWebVitals from "./reportWebVitals";

const RenderApp = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile(!!b);
    });
  }, []);
  console.log('isMobileisMobile',isMobile)
  return isMobile ? <MobileApp /> :<App />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RenderApp />);
reportWebVitals();
