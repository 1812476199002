import { Statistic } from "antd";
import TweenOne from "rc-tween-one";
import CountUp from "react-countup";
import {openNewTabURL} from '../../utils';
import { ReactComponent as RightIcon } from "../../assets/page_right.svg";
import bixin_wallet from "../../assets/bixin_wallet.png";
import "./index.less";

const formatter = (value) => <CountUp end={value} separator="," duration={1}/>;
const formatters = (value) => (
  <CountUp end={value} separator="," decimals={2} duration={1}/>
);

const BixinWallet = () => {
  return (
    <div className="bixin-wallet-root">
      <div className="page-content bixin-wallet-page-content">
        <div className="bixin-wallet-left">
          <TweenOne
            animation={{ x: 0,opacity: 1, duration: 500 }}
            style={{ transform: "translate(-100px, 0px)",opacity: 0 }}
          >
            <div className="bixin-wallet-title">BIXIN.COM</div>
            {/* <div className="bixin-wallet-statistic">
              <div>
                <div>
                  <Statistic value={3000000} formatter={formatter} />
                </div>
                <div>Annual Internal transactions</div>{" "}
              </div>
              <div>
                <div>
                  <Statistic
                    prefix="$"
                    value={1850000}
                    formatter={formatters}
                  />
                </div>
                <div>Annual Cost Savings for Users</div>{" "}
              </div>
            </div> */}
            <div className="bixin-wallet-des">
              <p>
                BIXIN.COM, formerly known as HaoBTC, established in 2014, is a virtual asset service platform authorized and regulated by regulatory authorities, providing users with "Simple, yet Secure" virtual asset services. The platform has been safely operated for nine years with zero security breaches, providing institutional-level protection for users' VAs through hot and cold wallet segregation, 
                multiple signatures, and military-grade hardware encryption mechanisms. 
              </p>
              {/* <p>
                BIXIN.COM is registered with AUSTRAC (an Australian government agency) as a Digital Currency Exchange Provider and Remittance Services Provider.  All business operations of bixin.com are carried out under strict supervision and comply with relevant laws and regulations.
              </p> */}
            </div>
            <div className="bixin-wallet-btn" onClick={()=>{
              openNewTabURL('https://www.bixin.com/')
            }}>
              ENTER <RightIcon />
            </div>
          </TweenOne>
        </div>
        <div className="bixin-wallet-right">
          <TweenOne
            animation={{ opacity: 1, duration: 1500 }}
            style={{ opacity: 0 }}
          >
            {" "}
            <img src={bixin_wallet} alt="bixin_wallet" />
          </TweenOne>
        </div>
      </div>
    </div>
  );
};

export default BixinWallet;
