import { openNewTabURL } from "../../utils";
import ecosystemImg from "../../assets/ecosystem.png";
import mpcWallet from "../../assets/mpc-wallet.svg";
import custodialWallet from "../../assets/custodial-wallet1.svg";
import hardwareWallet from "../../assets/hardware-wallet.svg";
import ventureCapital from "../../assets/venture-capital.svg";
// import publicChain from "../../assets/public-chain.svg";
import miningOperation from "../../assets/mining-operation.svg";
import whatsminer from "../../assets/whatsminer.svg";
import { ReactComponent as EcosystemRight } from "../../assets/ecosystem_right.svg";

import "./index.less";
const Ecosystem = () => {
  const itemArr = [
    { name: "MPC WALLET", img: mpcWallet, link:'https://openblock.com/#/' },
    { name: "VIRTUAL ASSETS EXCHANGE", img: custodialWallet, link:'https://www.bixin.com/' },
    { name: "HARDWARE WALLET", img: hardwareWallet, link:'https://onekey.so/' },
    { name: "VENTURE CAPITAL", img: ventureCapital, link:'https://bixinvc.com/' },
    // { name: "HIGH PERFORMANCE PUBLIC CHAIN", img: publicChain, link:'https://starcoin.org/en/' },
    { name: "MINING OPERATION", img: miningOperation },
    { name: "MINERS R&D, MANUFACTURING", img: whatsminer },
  ];
  return (
    <div className="h5-ecosystem-root" id="ecosystemRoot">
      <div className="h5-page-content h5-ecosystem-page-content">
        <div className="h5-ecosystem-left-title">BIXIN ECOSYSTEM</div>
        <img src={ecosystemImg} className="h5-ecosystem-img" alt="ecosystem" />
        {itemArr.map((item) => (
          <div
            key={item.name}
            className="h5-ecosystem-right-item"
            onClick={() => {
              if(!item.link) return;
              openNewTabURL(item.link)}
            }
          >
            <div className="h5-ecosystem-right-item-title">
              {item.name}
              {item.link && <EcosystemRight />}
            </div>
            <img src={item.img} alt={item.name} />
          </div>
        ))}
        <div className="h5-ecosystem-left-des">
          Founded in 2014, Bixin Group is a leading blockchain technology group
          specializing in blockchain security, and aims to promote and
          contribute to the crypto industry and Web 3.0-related communities. It
          has built a blockchain ecosystem consisting of the followings.
        </div>
        {/* <div className="h5-ecosystem-left">
          <div className="h5-ecosystem-left-content">
            
          </div>
        </div>
        <div className="h5-ecosystem-right">
          
        </div> */}
      </div>
    </div>
  );
};
export default Ecosystem;
