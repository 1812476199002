import { ReactComponent as LinkedinIcon } from "../../assets/linkedin.svg";
import "./index.less";
const Footer = () => {
  return (
    <div className="h5-footer-root">
      <div  className="h5-footer-icon-wrapper">
        <a href="https://www.linkedin.com/company/bixingroup" target="_blank" rel="noreferrer" className="h5-footer_icon"><LinkedinIcon /></a>
      </div>
      <div>
        {/* <div className="privacy">Privacy Policy</div> */}
        <div className="h5-footer-openblock">Bixin Group @2021 All Rights Reserved.</div>
      </div>
    </div>
  );
};
export default Footer