import TweenOne from "rc-tween-one";
import { openNewTabURL } from "../../utils";
import { ReactComponent as RightIcon } from "../../assets/page_right.svg";
import openblockImg from "../../assets/openblock.png";

import "./index.less";
const OpenBlock = () => {
  return (
    <div className="open-block-root">
      <div className="page-content open-block-page-content">
        <div className="open-block-left">
          <TweenOne
            animation={{ x: 0, opacity: 1, duration: 1000 }}
            style={{ transform: "translate(-100px, 0px)", opacity: 0 }}
          >
            <div className="open-block-title">OPENBLOCK</div>
            <div className="open-block-statistic">
              Web 3.0 on your fingertip
            </div>
            <div className="open-block-des">
              Compatible with the MetaMask plugin and the WalletConnect protocol
            </div>
            <div className="open-block-des">
              One-stop DApp aggregation supporting multi-chain ecosystems
            </div>
            <div className="open-block-des">
              MPC wallet without mnemonic facilitates seamlessly connecting to
              Web 3.0
            </div>
            <div className="open-block-des">
              Multi-party secure computing technology solves single-point
              failure
            </div>
            <div
              className="open-block-btn"
              onClick={() => {
                openNewTabURL("https://openblock.com/");
              }}
            >
              ENTER <RightIcon />
            </div>
          </TweenOne>
        </div>
        <div className="open-block-right">
          <TweenOne
            animation={{ opacity: 1, duration: 1500 }}
            style={{ opacity: 0 }}
          >
            <img src={openblockImg} alt="openblock" />
          </TweenOne>
        </div>
      </div>
    </div>
  );
};

export default OpenBlock;
