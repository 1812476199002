import TweenOne from "rc-tween-one";
import {openNewTabURL} from '../../utils';
import StarIconImg from "../../assets/ventures/star.png";
import ScrollIconImg from "../../assets/ventures/scroll.png";
import MintbaseIconImg from "../../assets/ventures/mintbase.png";
import SuiIconImg from "../../assets/ventures/sui.png";
import GnosissafeIconImg from "../../assets/ventures/gnosissafe.png";
import mina from "../../assets/ventures/mina.png";
import firefly from "../../assets/ventures/firefly.png";
import drift from "../../assets/ventures/drift.png";
import arrow from "../../assets/ventures/arrow.png";
import zcloack from "../../assets/ventures/zcloack.png";
import Aptos from "../../assets/ventures/Aptos.png";
import algo from "../../assets/ventures/algo.png";
import milayerzerona from "../../assets/ventures/layerzero.png";
import epns from "../../assets/ventures/epns.png";
import margin from "../../assets/ventures/margin.png";
import structure from "../../assets/ventures/structure.png";
import microbt from "../../assets/ventures/microbt.png";
import meson from "../../assets/ventures/meson.png";
import arweave from "../../assets/ventures/arweave.png";
import fewandfar from "../../assets/ventures/fewandfar.png";
import { ReactComponent as RightIcon } from "../../assets/page_right.svg";

import "./index.less";

const ventureArr = [
  {name:'Star',icon:StarIconImg,link:'https://staratlas.com/'},
  {name:'Scroll',icon:ScrollIconImg,link:'https://scroll.io/'},
  {name:'Mintbase',icon:MintbaseIconImg,link:'https://www.mintbase.io/'},
  {name:'Sui',icon:SuiIconImg,link:'https://sui.io/'},
  {name:'Gnosissafe',icon:GnosissafeIconImg,link:'https://gnosis-safe.io/'},
  {name:'mina',icon:mina,link:'https://minaprotocol.com/'},
  {name:'firefly',icon:firefly,link:'https://firefly.exchange/'},
  {name:'drift',icon:drift,link:'https://www.drift.trade/'},
  {name:'arrow',icon:arrow,link:'https://www.arrow.markets/'},
  {name:'zcloack',icon:zcloack,link:'https://zcloak.network/'},
  {name:'Aptos',icon:Aptos,link:'https://aptoslabs.com/'},
  {name:'algo',icon:algo,link:'https://www.algorand.com/'},
  {name:'milayerzerona',icon:milayerzerona,link:'https://layerzero.network/'},
  {name:'epns',icon:epns,link:'https://epns.io/'},
  {name:'margin',icon:margin,link:'https://xmargin.io/'},
  {name:'structure',icon:structure,link:'https://structure.fi/'},
  {name:'microbt',icon:microbt,link:'https://www.microbt.com/'},
  {name:'meson',icon:meson,link:'https://meson.network/'},
  {name:'arweave',icon:arweave,link:'https://www.arweave.org/'},
  {name:'fewandfar',icon:fewandfar,link:'https://fewfar.com/'},
]
const BixinVentures = ({step}) => {
  return (
    <div className="bixin-ventures-root">
      <div className="page-content bixin-ventures-page-content">
        <div className="bixin-ventures-left">
          <TweenOne
            animation={{ opacity: 1, duration: 1300 }}
            style={{ opacity: 0 }}
            moment={step === 7 ? 0 : null}
          >
            <div className="bixin-ventures-title">BIXIN VENTURES</div>
            <div className="bixin-ventures-des">
              Bixin Ventures is a global firm focusing on early-stage
              investments in blockchain companies to accelerate the next
              disruptive Web 3.0 innovation.
            </div>
            <div className="bixin-ventures-btn" onClick={()=>{
              openNewTabURL('https://bixinvc.com/')
            }}>
              ENTER <RightIcon />
            </div>
          </TweenOne>
        </div>
        <div className="bixin-ventures-right">
          <TweenOne
            animation={{ opacity: 1, duration: 1500 }}
            style={{ opacity: 0 }}
            moment={step === 7 ? 0 : null}
          >
            <div className="bixin-ventures-right-content">
              {ventureArr.map((item)=>(
                 <img key={item.name} src={item.icon} alt={item.name} onClick={()=>openNewTabURL(item.link)}/>
              ))}
            </div>
          </TweenOne>
        </div>
      </div>
    </div>
  );
};

export default BixinVentures;
