import TweenOne from "rc-tween-one";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { openNewTabURL } from "../../utils";
import { ReactComponent as RightIcon } from "../../assets/page_right.svg";
import onekey_classic from "../../assets/onekey_classic.png";
import onekey_mini from "../../assets/onekey_mini.png";
import onekey_touch from "../../assets/onekey_touch.png";
import onekey_lite from "../../assets/onekey_lite.png";
import "./index.less";
const OneKey = () => {
  return (
    <div className="one-key-root">
      <div className="page-content one-key-page-content">
        <div className="one-key-left">
          <TweenOne
            animation={{ x: 0, opacity: 1, duration: 500 }}
            style={{ transform: "translate(-100px, 0px)", opacity: 0 }}
          >
            <div className="one-key-title">
              ONEKEY
            </div>
            <div className="one-key-statistic">
              Encrypted hardware wallet
            </div>
            <div className="one-key-des">
              Simple yet secure. Truly open source, with no hidden codes. Open
              to public inspection.
            </div>
            <div
              className="one-key-btn"
              onClick={() => {
                openNewTabURL("https://onekey.so/");
              }}
            >
              ENTER <RightIcon />
            </div>
          </TweenOne>
        </div>
        <div className="one-key-right">
          <TweenOne
            animation={{
              x: 0,
              opacity: 1,
              duration: 500,
            }}
            style={{ transform: "translate(200px, 0px)", opacity: 0 }}
          >
            <Swiper
              id="onekeyswiper"
              key="onekeyswiper"
              // direction="vertical"
              modules={[Navigation]}
              navigation
              slidesPerView={2}
            >
              <SwiperSlide>
                <div className="onkey-slide">
                  <div className="onkey-slide-name">OneKey Classic</div>
                  <img src={onekey_classic} alt="onekey_classic" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="onkey-slide">
                  <div className="onkey-slide-name">OneKey Mini</div>
                  <img src={onekey_mini} alt="onekey_mini" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="onkey-slide">
                  <div className="onkey-slide-name">OneKey Touch</div>
                  <img src={onekey_touch} alt="onekey_touch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="onkey-slide">
                  <div className="onkey-slide-name">OneKey Lite</div>
                  <img
                    src={onekey_lite}
                    alt="onekey_lite"
                    style={{ width: "90%", marginTop: "30px" }}
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </TweenOne>
          <TweenOne
            animation={{ opacity: 1, duration: 1000 }}
            style={{ opacity: 0 }}
          >
            <div className="one-key-splite" />
            <div className="one-key-splite-des">
              No storage of users’ private keys or recovery phrases. All
              hardware and software are open sources. End-to-end encryption and
              industry-leading encryption technology allow users to keep
              information locally.
            </div>
          </TweenOne>
        </div>
      </div>
    </div>
  );
};

export default OneKey;
