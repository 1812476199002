import React from "react";
import whysecurity from "../../assets/why_security.png";
import whycapital from "../../assets/why-capital.png";
import whyminingy from "../../assets/why-mining.png";

import "./index.less";
const WhyUs = () => {
  const childWrapper = [
    {
      icon: whysecurity,
      name: "SECURITY",
      des: "Onekey, one of the best selling hardware wallets; BIXIN.COM, nine years zero breach, trusted by millions.",
    },
    {
      icon: whycapital,
      name: "CAPITAL",
      des: "Bixin Ventures is a global firm focusing on early-stage investments in blockchain companies to accelerate the next disruptive Web 3.0 innovation.",
    },
    {
      icon: whyminingy,
      name: "MINING",
      des: "Bixin Group has been a trailblazer in Bitcoin mining and contributed up to 5% to 10% of the computing power at its peak over the past eight years in the global Bitcoin network.",
    },
  ];

  return (
    <div className="h5-whyus-root" id="whyusRoot">
      <div className="h5-page-content h5-why-page-content">
        <div key="why-title" className="h5-why-title">
          Why Us
        </div>

        {childWrapper.map((item) => (
          <div key={item.name}>
            <div className="h5-wyhus-childWrapper">
              <img
                className="h5-wyh-item-img"
                src={item.icon}
                alt={item.name}
              />
              <div className="h5-wyh-item-title">{item.name}</div>
              <div className="h5-wyh-item-des">{item.des}</div>
            </div>
          </div>
        ))}
      </div>
      {/* </OverPack> */}
    </div>
  );
};

export default WhyUs;
