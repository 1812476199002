import React from "react";
import Footer from "./Footer";
import HomePage from "./Home";
import AboutUs from "./AboutUs";
import WhyUs from "./WhyUs";
import Ecosystem from "./Ecosystem";
import BixinWallet from "./BixinWallet";
import OpenBlock from "./OpenBlock";
import OneKey from "./Onekey";
// import Starcoin from './Starcoin'
import BixinVentures from './BixinVentures'
import Header from './Header'
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/mousewheel";
import "./index.less";

const Home = () => {
  return (
    <div className="templates-wrapper">
      <Header />
      <HomePage />
      <AboutUs />
      <WhyUs />
      <Ecosystem />
      <BixinWallet />
      <OpenBlock />
      <OneKey />
      {/* <Starcoin /> */}
      <BixinVentures/>
      <Footer />
      {/* 
      
      
      <BixinWallet step={step} />
      <OpenBlock step={step} />
      
      
      <BixinVentures step={step} />
       */}
    </div>
  );
};

export default Home;
