import { useSwiper } from "swiper/react";
import "./index.less";

const Point = (props) => {
  const { step, linkName, setStep } = props;
  const swiper = useSwiper();

  const aa = (index) => {
    swiper.slideTo(index)
    setStep(index);
  };
  const children = linkName
    .map((item, index) => {
      const className = `point ${step === index ? "active" : ""}`;
      return (
        <div key={item} className={className} onClick={() => aa(index)}>
          {item}
        </div>
      );
    })
    .filter((item) => item);
  return <div className="point-wrapper">{children}</div>;
};

export default Point;
