import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import {openNewTabURL} from '../../utils';
import ecosystemImg from "../../assets/ecosystem.png";
import mpcWallet from "../../assets/mpc-wallet.svg";
import custodialWallet from "../../assets/custodial-wallet1.svg";
import hardwareWallet from "../../assets/hardware-wallet.svg";
import ventureCapital from "../../assets/venture-capital.svg";
// import publicChain from "../../assets/public-chain.svg";
import miningOperation from "../../assets/mining-operation.svg";
import whatsminer from "../../assets/whatsminer.svg";
import { ReactComponent as EcosystemRight } from "../../assets/ecosystem_right.svg";

import "./index.less";
const Ecosystem = ({step}) => {
  const itemArr = [
    { name: "MPC WALLET", img: mpcWallet, link:'https://openblock.com/#/' },
    { name: "VIRTUAL ASSETS EXCHANGE", img: custodialWallet, link:'https://www.bixin.com/' },
    { name: "HARDWARE WALLET", img: hardwareWallet, link:'https://onekey.so/' },
    { name: "VENTURE CAPITAL", img: ventureCapital, link:'https://bixinvc.com/' },
    // { name: "HIGH PERFORMANCE PUBLIC CHAIN", img: publicChain, link:'https://starcoin.org/en/' },
    { name: "MINING OPERATION", img: miningOperation },
    { name: "MINERS R&D, MANUFACTURING", img: whatsminer },
  ];
  // if(step !== 3) return null
  return (
    <div className="ecosystem-root" id="ecosystemRoot">
      <div className="page-content ecosystem-page-content">
        <div className="ecosystem-left">
          <div className="ecosystem-left-content">
            <TweenOne
              animation={{  opacity: 1, duration: 1500 }}
              style={{ opacity: 0 }}
              moment={step === 3 ? 0 : null}
            >
               <img src={ecosystemImg} alt="ecosystem" />
              <div className="ecosystem-left-title">BIXIN ECOSYSTEM</div>
              <div className="ecosystem-left-des">
                Founded in 2014, Bixin Group is a leading blockchain technology
                group specializing in blockchain security, and aims to promote
                and contribute to the crypto industry and Web 3.0-related
                communities. It has built a blockchain ecosystem consisting of
                the followings.
              </div>
            </TweenOne>
          </div>
        </div>
        <div className="ecosystem-right">
          <QueueAnim key="ecosystem-list" type={["left", "right"]}>
            {itemArr.map((item) => (
              <div key={item.name} className="ecosystem-right-item" onClick={()=>{
                if(!item.link) return;
                openNewTabURL(item.link)
              }}>
                <div className="ecosystem-right-item-title">
                  {item.name}
                  {item?.link && <EcosystemRight />}
                </div>
                <img src={item.img} alt={item.name} />
              </div>
            ))}
          </QueueAnim>
        </div>
      </div>
    </div>
  );
};
export default Ecosystem;
