import { ReactComponent as HomeBixinTitle } from "../../assets/home_bixin_title.svg";
import "./index.less";

const HomePage = () => {
  return (
    <div className="h5-home-root" id="homeRoot">
      {/* <div className="home-root-bg" /> */}
      <div className="h5-home-wrapper" key="home-ani">
        <div className="h5-home-title">
          <HomeBixinTitle />
        </div>
        <div className="h5-home-des">
          <div>A Decade of Excellence</div>
          <div>Enable the Next Disruptive Web 3.0 Innovation</div>
        </div>
      </div>
    </div>
  );
};
export default HomePage;
